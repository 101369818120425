import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const ErrorPage = () => (
  <Layout>
    <PageHeader>
      Oh no!
    </PageHeader>
    <div id="content">
      You broke it
    </div>
  </Layout>
);

export default ErrorPage;